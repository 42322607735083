<template>
  <section>
    <b-row
      ><b-col md="12">
        <!-- <b-card> -->
        <b-tabs>
          <b-tab active>
            <!-- title -->
            <template #title>
              <feather-icon icon="ArchiveIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Detail</span>
            </template>

            <b-modal
              v-model="Modal"
              id="modal-tambah"
              centered
              size="sm"
              :title="titleModal"
            >
              <b-card-text>
                <b-form>
                  <b-form-group label="Paket" label-for="paket">
                    <b-form-select
                      v-model="jadwalujianDataId.paket_id"
                      :options="optpaket"
                    />
                  </b-form-group>
                  <!-- <b-form-group label="Mata Pelajaran" label-for="mapel">
                    <b-form-select
                      v-model="jadwalujianDataId.mapel.id"
                      :options="optujian"
                    />
                  </b-form-group> -->
                  <b-form-group label="Nama Jadwal" label-for="name">
                    <b-form-input
                      id="name"
                      v-model="jadwalujianDataId.name"
                      placeholder="name"
                    />
                  </b-form-group>
                  <b-form-group label="Tanggal & Waktu Mulai" label-for="start_time">
                    <flat-pickr
                      v-model="jadwalujianDataId.start_time"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i:S',
                        time_24hr: true,
                      }"
                    />
                    <!-- <b-form-input
                        id="start_time"
                        v-model="jadwalujianDataId.start_time"
                        placeholder="start_time"
                      /> -->
                  </b-form-group>
                  <b-form-group label="Tanggal & Waktu Akhir" label-for="end_time">
                    <flat-pickr
                      v-model="jadwalujianDataId.end_time"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i:S',
                        time_24hr: true,
                      }"
                    />
                    <!-- <b-form-input
                        id="end_time"
                        v-model="jadwalujianDataId.end_time"
                        placeholder="end_time"
                      /> -->
                  </b-form-group>
                  <b-form-group label="Timer" label-for="timer">
                    <b-form-input
                      id="timer"
                      v-model="jadwalujianDataId.timer"
                      placeholder="timer"
                    />
                  </b-form-group>
                  <b-form-group label="Minimal Nilai" label-for="min_nilai">
                    <b-form-input
                      id="min_nilai"
                      v-model="jadwalujianDataId.min_nilai"
                      placeholder="min_nilai"
                    />
                  </b-form-group>
                  <!-- <b-form-group label="Soal" label-for="soal_ids">
                      <b-form-input
                        id="soal_ids"
                        v-model="jadwalujianDataId.soal_ids"
                        placeholder="soal_ids"
                      />
                    </b-form-group> -->
                </b-form>
              </b-card-text>

              <template #modal-footer>
                <div class="w-100">
                  <p class="float-left mb-0"></p>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="outline-success"
                    class="mr-1 btn-icon"
                    @click.prevent="Tambah"
                  >
                    <feather-icon icon="SaveIcon" class="mr-25" />{{ label || "Tambah" }}
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    class="float-right btn-icon"
                    @click.prevent="tutupModal"
                  >
                    <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                  </b-button>
                </div>
              </template>
            </b-modal>
            <b-card>
              <!-- form -->
              <b-row>
                <b-col cols="12" xl="12">
                  <table class="mt-xl-0 w-100">
                    <tr>
                      <th class="pb-50">
                        <feather-icon icon="CalendarIcon" class="mr-75" />
                        <span class="font-weight-bold">Nama Jadwal</span>
                      </th>
                      <td class="pb-50">
                        :
                        {{ jadwalujianDataId.name }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <feather-icon icon="CheckIcon" class="mr-75" />
                        <span class="font-weight-bold">Tanggal & Waktu Mulai</span>
                      </th>
                      <td class="pb-50 text-capitalize">
                        :
                        {{
                          jadwalujianDataId.is_date_in_subject == 1
                            ? "Di masing-masing ujian"
                            : humanDateTime(jadwalujianDataId.start_time)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <feather-icon icon="StarIcon" class="mr-75" /><span
                          class="font-weight-bold"
                          >Tanggal & Waktu Akhir</span
                        >
                      </th>
                      <td class="pb-50 text-capitalize">
                        :
                        {{
                          jadwalujianDataId.is_date_in_subject == 1
                            ? "Di masing-masing ujian"
                            : humanDateTime(jadwalujianDataId.end_time)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <feather-icon icon="UsersIcon" class="mr-75" />
                        <span class="font-weight-bold">Peserta (Total/Maks Kuota)</span>
                      </th>
                      <td class="pb-50">
                        :
                        {{
                          jadwalujianDataId.sum_member + "/" + jadwalujianDataId.max_quota
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <feather-icon icon="CheckIcon" class="mr-75" />
                        <span class="font-weight-bold">Kategori Paket</span>
                      </th>
                      <td class="pb-50 text-capitalize">
                        :
                        {{ jadwalujianDataId.paket.name }}
                      </td>
                    </tr>
                  </table>
                </b-col>
                <!-- <b-col cols="3" xl="3">
                  <b-button variant="primary" @click="ModalUbah(jadwalujianDataId)" block>Edit</b-button>
                </b-col> -->
                <hr />
                
              </b-row>
            </b-card>
          </b-tab>
          <!--/ general tab -->
          <b-tab>
            
            <template #title>
              <feather-icon icon="CheckCircleIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Mata Pelajaran</span>
            </template>
           <!-- <b-card>
             <b-col md="12">
              <b-tabs pills vertical>
                <div v-for="data in dataujian" :key="data.id">
                <b-tab>
                  <template #title >
              <span class="font-weight-bold" :id="`popover-1-${data.id}`">{{ data.ujian_name }}</span>
              <b-popover :target="`popover-1-${data.id}`" triggers="hover" placement="left">
      <template #title>{{data.ujian_name}}</template>
      <table class="mt-xl-0 w-100">
                    <tr>
                      <th class="pb-50">
                        <feather-icon icon="ClockIcon" class="mr-75" />
                        <span class="font-weight-bold">Timer</span>
                      </th>
                      <td class="pb-50">
                        :
                        {{ data.timer }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <feather-icon icon="CheckIcon" class="mr-75" />
                        <span class="font-weight-bold">Passing Grade</span>
                      </th>
                      <td class="pb-50 text-capitalize">
                        :
                        {{
                          data.passing_grade
                        }}
                      </td>
                    </tr>
                  </table>
    </b-popover>
            </template>
                </b-tab>
              </div>
              </b-tabs> 
                  
                </b-col>
              </b-card> -->
              <b-card>
                <b-table
                  striped
                hover
                responsive
                    v-model="ujians"
                    :fields="
                      jadwalujianDataId.is_date_in_subject == false ||
                      jadwalujianDataId.is_date_in_subject == 0
                        ? fields
                        : fields2
                    "
                    :items="dataujian"
                  >
                    <!-- A virtual column -->
                    <template #cell(index)="data">
                      {{ data.index + 1 }}
                    </template>

                    <!-- A custom formatted column -->
                    <template #cell(action)="row">
                  <div v-if="dataujian.length == 1">
                      <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25"
                          style="height: 100px"
                          />
                        </template>
                        <b-dropdown-item :to="{ name:'admin-jadwal-detail-soal',query: {ujian_id: row.item.ujian_id} }">
                          <feather-icon icon="PlusIcon" class="mr-50" />
                          <span>Buat Soal</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="ModalHapus(row.item)">
                          <feather-icon icon="TrashIcon" class="mr-50" />
                          <span>Hapus</span>
                        </b-dropdown-item>
                      </b-dropdown>
                      </div>
                  <div v-if="dataujian.length != 1">
                      <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25"
                          />
                        </template>
                        <b-dropdown-item :to="{ name:'admin-jadwal-detail-soal',query: {ujian_id: row.item.ujian_id} }">
                          <feather-icon icon="PlusIcon" class="mr-50" />
                          <span>Buat Soal</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="ModalHapus(row.item)">
                          <feather-icon icon="TrashIcon" class="mr-50" />
                          <span>Hapus</span>
                        </b-dropdown-item>
                      </b-dropdown>
                      </div>
                    </template>
                    <template #cell(start_time)="data">
                      <!-- <flat-pickr
                        v-model="row.item.start_time"
                        class="form-control"
                        :config="{
                          enableTime: true,
                          dateFormat: 'Y-m-d H:i:S',
                          time_24hr: true,
                        }"
                      /> -->
                      {{ humanDateTime(data.item.start_time) }}
                    </template>
                    <template #cell(end_time)="data">
                      <!-- <flat-pickr
                        v-model="data.item.end_time"
                        class="form-control"
                        :config="{
                          enableTime: true,
                          dateFormat: 'Y-m-d H:i:S',
                          time_24hr: true,
                        }"
                      /> -->
                      {{ humanDateTime(data.item.end_time) }}
                    </template>

                    <template #cell(timer)="data">
                      {{ data.item.timer }}
                      <!-- <b-form-input
                        v-model="data.item.timer"
                        size="sm"
                        placeholder="timer"
                        type="number"
                      /> -->
                    </template>
                    <template #cell(passing_grade)="data">
                      {{ data.item.passing_grade }}
                      <!-- <b-form-input
                        v-model="data.item.passing_grade"
                        size="sm"
                        placeholder="passing_grade"
                        type="number"
                      /> -->
                    </template>
                    <template #cell(sum_soal)="data">
                      {{ data.item.sum_soal }}
                    </template>
                  </b-table>
              </b-card>
            <!-- <pilih-ujian :jadwalujianDataId="jadwalujianDataId" /> -->
          </b-tab>
          <!-- Keunggulan -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="UsersIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Member</span>
            </template>
            <pilih-member :jadwalujianDataId="jadwalujianDataId" />
          </b-tab>

          <!-- social links -->
        </b-tabs>
        <!-- </b-card> -->
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BPopover,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormFile,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormTextarea,
  VBTooltip,
  BDropdownItem,
  BDropdown,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import PilihUjian from "./ujian/Index.vue";
import PilihMember from "./member/Index.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BPopover,
    PilihUjian,
    PilihMember,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormTextarea,
    VBTooltip,
    ToastificationContent,
    flatPickr,
  BDropdownItem,
  BDropdown,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      Modal: false,
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      paketFAQData: [],
      ujians: [],
      dataujian: [],
      jadwalujianDataId: {},
      optpaket: [],
      optujian: [],
      // form: {
      //     id: null,
      //     name: null,
      //     price: null,
      //     paket_id: null,
      // },
      //Modal
      titleModal: null,
      Modal: false,
      ModalUploadThumbnail: false,
      //Table

      fields: [
        { key: "index", label: "#" },
        { key: "ujian_name", label: "Mata Pelajaran", sortable: true },
        { key: "sum_soal", label: "Jumlah Soal", sortable: true },
        { key: "timer", label: "Timer (menit)", sortable: true },
        { key: "passing_grade", label: "Passing Grade", sortable: true },
        { key: "action", label: "#" },
      ],
      fields2: [
        { key: "index", label: "#" },
        { key: "ujian_name", label: "Mata Pelajaran", sortable: true },
        { key: "start_time", label: "Tgl Mulai", sortable: true },
        { key: "end_time", label: "Tgl Berakhir", sortable: true },
        { key: "timer", label: "Timer (Menit)", sortable: true },
        { key: "sum_soal", label: "Soal", sortable: true },
        { key: "passing_grade", label: "Passing Grade", sortable: true },
        { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "name", value: "Pertanyaan" },
        { id: "price", value: "Jawaban" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Paket",
      search: null,
      idd: null,
    };
  },
  watch: {},
  computed: {
    apiFile() {
      return "http://file.elp-dev.my.id/";
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    ModalUbah(item) {
      this.jadwalujianDataId = item;
      this.jadwalujianDataId.paket_id = item.paket.id;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Ujian";
      this.getPaket();
      this.getUjian();
      this.Modal = true;
      // this.ModalEdit = true;
    },
    doFormatRupiah() {
      this.jadwalujianDataId.price = this.formatRupiah(this.jadwalujianDataId.price);
      this.jadwalujianDataId.max_quota = this.formatRupiah(
        this.jadwalujianDataId.max_quota
      );
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ModalUpload() {
      this.id = this.$route.params.id;
      this.activeAction = "upload";
      this.doFormatRupiah();
      this.titleModal = "Upload Thumbnail";
      this.ModalUploadThumbnail = true;
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.doFormatRupiah();
      this.titleModal = "Tambah Paket";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 1; //soft delete
          this.$store
            .dispatch("jadwaladmin/createUpdateDelete", [data])
            .then(() => {
              this.getDataById();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (this.jadwalujianDataId.name == null || this.jadwalujianDataId.name == "") {
        this.pesanGagal();
        return false;
      }
      let payload = {
        id: this.jadwalujianDataId.id,
        type_ujian: this.jadwalujianDataId.category.id,
        user_id: this.user.id,
        mapel_id: this.jadwalujianDataId.mapel.id,
        name: this.jadwalujianDataId.name,
        shortname: this.jadwalujianDataId.shortname,
        soal_ids: this.jadwalujianDataId.soal_ids,
        start_time: this.jadwalujianDataId.start_time,
        end_time: this.jadwalujianDataId.end_time,
        timer: this.jadwalujianDataId.timer,
        min_nilai: this.jadwalujianDataId.min_nilai,
      };

      // if (this.id) {
      //   payload.id = this.id;
      // }

      this.$store
        .dispatch("jadwaladmin/save", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.Modal = false;
          // this.ModalEdit = false;
          this.getDataById();
          this.displaySuccess({
            text: "Ujian berhasil di" + this.activeAction,
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getDataById() {
      this.$store
        .dispatch("jadwaladmin/indexId", this.$route.params.id)
        .then((response) => {
          let jadwalujianDataId = response.data;
          this.jadwalujianDataId = jadwalujianDataId;
          this.getUjian();
          // this.doFormatRupiah();
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    async getPaket() {
      // this.loading = true;
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        user_id: this.user.id,
      };
      this.$store
        .dispatch("paketTryout/index", payload)
        .then((response) => {
          // this.loading = false;
          let optpaket = response.data.data;
          optpaket.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optpaket = optpaket;
        })
        .catch((error) => {
          // this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },

    // async getUjian() {
    //   // this.loading = true;
    //   const payload = {
    //     user_id: this.user.id,
    //   };
    //   this.$store
    //     .dispatch("adminujian/index", payload)
    //     .then((response) => {
    //       // this.loading = false;
    //       let optujian = response.data.data;
    //       optujian.map((item) => {
    //         item.value = item.id;
    //         item.text = item.name;
    //       });
    //       this.optujian = optujian;
    //     })
    //     .catch((error) => {
    //       // this.loading = false;
    //       this.$root.$emit("errorData", error);
    //     });
    // },
    async getUjian() {
      this.loading = true;
      const payload2 = {
        jadwal_id: this.$route.params.id,
      };
      this.$store
        .dispatch("jadwaladmin/indexujian", payload2)
        .then((response) => {
          this.loading = false;
          let dataujian = response.data.data;
          this.dataujian = dataujian;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
  },
  created() {},
  mounted() {
    // this.getDataPaketFAQ();
    this.getDataById();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
