<template>
  <section>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-body>
            <b-card-title>
              Pilih Member Paket ({{ itemMember.length }})
            </b-card-title>
            <b-card-text>
              <b-form>
                <b-row>
                  <b-col cols="12" class="mb-3">
                    <b-table
                      :fields="memberFields"
                      :items="itemMember"
                      class="w-100"
                      responsive="sm"
                      search
                      :per-page="10"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >
                      <template #cell(index)="{ item }">
                        <b-form-checkbox
                          v-model="selectedMemberItem"
                          :value="item"
                        />
                      </template>

                      <template #cell(active)="{ item }">
                        <i>
                          <strong class="text-success" v-if="item.active == 1"
                            >Aktif</strong
                          >
                          <strong class="text-danger" v-else
                            >Tidak Aktif</strong
                          >
                        </i>
                      </template>

                      <template #cell(type_member)="{ item }">
                        <b-badge variant="primary">{{
                          item.type_member == "member" ? "Member" : "Non-Member"
                        }}</b-badge>
                      </template>
                    </b-table>
                  </b-col>
                  <b-col cols="12">
                    <div class="d-flex justify-content-center">
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        size="sm"
                        class="my-0 text-center"
                      />
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-text>
            <b-button
              variant="primary"
              @click.prevent="TambahMember"
              class="mb-1 float-right"
            >
              Simpan
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card>
          <h4 class="mb-2">Peserta Jadwal Ini ({{ dataMember.length }})</h4>
          <b-row>
            <b-col>
              <b-table
                small
                responsive="sm"
                :per-page="perPage2"
                :current-page="currentPage2"
                :sort-by.sync="sortBy2"
                :sort-desc.sync="sortDesc2"
                :sort-direction="sortDirection2"
                :filter="filter2"
                :filter-included-fields="filterOn2"
                @filtered="onFiltered2"
                :fields="fields2"
                :items="dataMember"
              >
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <!-- A custom formatted column -->
                <template #cell(action)="row">
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item @click="ModalHapus(row.item)">
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Hapus</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
                <template #cell(start_time)="data">
                  {{ humanDateTime(data.item.start_time) }}
                </template>
                <template #cell(end_time)="data">
                  {{ humanDateTime(data.item.end_time) }}
                </template>

                <template #cell(timer)="data">
                  {{ data.item.timer }}
                </template>
                <template #cell(passing_grade)="data">
                  {{ data.item.passing_grade }}
                </template>
                <template #cell(max_quota)="data">
                  {{ formatRupiah(data.item.max_quota) }}
                </template>
                <template #cell(action)="row">
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item @click="ModalUbah(row.item)">
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="ModalHapus(row.item)">
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Hapus</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>

              <div class="d-flex justify-content-center">
                <b-pagination
                  v-model="currentPage2"
                  :total-rows="totalRows2"
                  :per-page="perPage2"
                  size="sm"
                  class="my-0 text-center"
                />
              </div>
            </b-col> </b-row
        ></b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
import {
  BOverlay,
  BFormTextarea,
  BFormFile,
  BRow,
  BCol,
  BTable,
  BTableSimple,
  BTbody,
  BThead,
  BTfoot,
  BTr,
  BTd,
  BTh,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  VBTooltip,
  BLink,
  BDropdownItem,
  BDropdown,
  BTabs,
  BTab,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  props: ["jadwalujianDataId"],
  components: {
    vSelect,
    quillEditor,
    BTabs,
    BTab,
    BDropdownItem,
    BDropdown,
    BOverlay,
    BFormTextarea,
    BFormFile,
    flatPickr,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BTableSimple,
    BTbody,
    BThead,
    BTfoot,
    BTr,
    BTd,
    BTh,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    VBTooltip,
    BLink,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      ujian_id: null,
      filtermapel: null,
      optmapel: [],
      filterujian: null,
      selectedMemberItem: [],
      optMember: [],
      optTipe: [
        { id: "pg", value: "Pilihan Ganda" },
        { id: "multiple_choice", value: "Multiple Choice" },
        { id: "benar_salah", value: "Benar Salah" },
        { id: "pauli", value: "Pauli" },
      ],
      bsSoal: [
        { text: "Benar", value: "benar" },
        { text: "Salah", value: "salah" },
      ],
      editor_options: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
            ],
          },
        },
      },
      judulujian: [],
      id: null,
      activeAction: null,
      label: "Simpan",
      jadwalData: [],
      memberFields: [
        {
          key: "index",
          label: "#",
          thStyle: { width: "1%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        { key: "name", label: "Nama" },
        {
          key: "active",
          label: "Keaktifan Member",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "type_member",
          label: "Tipe Member",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      itemMember: [],
      dataMember: [],
      formUjian: {
        id: null,
        jadwal_id: null,
        ujian_id: null,
        start_time: "",
        end_time: "",
        timer: 0,
        passing_grade: 0,
      },
      formMember: [],
      //Modal
      titleModal: null,
      Modal: false,
      ModalUploadThumbnail: false,
      ModalEdit: false,
      //Table Soal
      fields: [
        { key: "index", label: "No" },
        { key: "check", label: "Pilih", sortable: true },
        { key: "mapel.nama_mapel", label: "Mata Pelajaran", sortable: true },
        { key: "category.nama_ujian", label: "Kategori", sortable: true },
        { key: "name", label: "Nama Ujian", sortable: true },
      ],
      total: [],
      record: [],
      optFilter: [
        { id: "name", value: "Judul" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      //Table Soal pilihan
      fields2: [
        { key: "index", label: "No" },
        { key: "user_name", label: "Nama Member", sortable: true },
        { key: "action", label: "#" },
      ],
      total2: [],
      record2: [],
      optFilter2: [
        { id: "name", value: "Judul" },
        // { id: "nip", value: "NIP" },
      ],
      perPage2: 10,
      pageOptions2: [10, 30, 50],
      totalRows2: 1,
      currentPage2: 1,
      sortBy2: "",
      sortDesc2: false,
      sortDirection2: "asc",
      filter2: null,
      filterOn2: [],
      // filter: "Paket",
      search: null,
      idd: null,
      loading: false,
      selectMode: "multi",
      selected: [],
      select_soal: [],
    };
  },
  watch: {
    selectedMemberItem(val) {
      val.forEach((member) => {
        let item = this.itemMember.find((item) => item.id == member.id);

        if (item) {
          let exist = this.formMember.find(
            (mem) => mem.user_id == item.user_id
          );
          if (exist === undefined) {
            this.formMember.push({
              jadwal_id: this.$route.params.id,
              user_id: item.user_id,
            });
          }
        }
      });
    },
    select_soal(array) {
      if (array && array.length > 0) {
        let ujiand = array;
        ujiand.map((item) => {
          item.ujian_id = item.id;
          item.jadwal_id = this.$route.params.id;
          item.start_time = this.jadwalujianDataId.start_time;
          item.end_time = this.jadwalujianDataId.end_time;
          item.timer = 0;
          item.passing_grade = 0;
        });
        // let judul_soal = array.map((item) => item.id);
        // this.jadwalujianDataId.soal_ids = ujiand.join(",");
        this.judulujian = ujiand;
      }
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    sortOptions2() {
      // Create an options list from our fields
      return this.fields2
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    update() {
      if (
        this.jadwalujianDataId.name == null ||
        this.jadwalujianDataId.name == ""
      ) {
        this.pesanGagal();
        return false;
      }
      let payload = {
        id: this.$route.params.id,
        type_ujian: this.jadwalujianDataId.category.id,
        user_id: this.user.id,
        mapel_id: this.jadwalujianDataId.mapel.id,
        name: this.jadwalujianDataId.name,
        shortname: this.jadwalujianDataId.shortname,
        soal_ids: this.jadwalujianDataId.soal_ids,
        start_time: this.jadwalujianDataId.start_time,
        end_time: this.jadwalujianDataId.end_time,
        timer: this.jadwalujianDataId.timer,
        min_nilai: this.jadwalujianDataId.min_nilai,
      };

      // if (this.id) {
      //   payload.id = this.id;
      // }

      this.$store
        .dispatch("adminujian/save", [payload])
        .then(() => {
          if (this.activeAction == "ubah") {
            this.id = null;
          }
          this.resetForm();
          this.Modal = false;
          // this.ModalEdit = false;
          this.getDataoptMember();
          this.jadwalujianDataId;
          this.displaySuccess({
            text: "Ujian berhasil di" + this.activeAction,
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },

    doFormatRupiah() {
      this.form.price = this.formatRupiah(this.form.price);
      this.form.max_quota = this.formatRupiah(this.form.max_quota);
      this.form.sum_subtest = this.formatRupiah(this.form.sum_subtest);
    },
    tutupModal() {
      this.Modal = false;
      this.resetForm();
      this.getData();
    },
    resetForm() {
      this.formMember = [];
      this.selectedMemberItem = [];
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFiltered2(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows2 = filteredItems.length;
      this.currentPage2 = 1;
    },
    ModalUpload(item) {
      this.id = item.id;
      this.form = item;
      this.doFormatRupiah();
      this.activeAction = "upload";
      this.titleModal = "Upload Thumbnail";
      this.ModalUploadThumbnail = true;
    },
    ModalShow() {
      this.doFormatRupiah();
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Paket";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      // this.id = item.id;
      (this.formMember = {
        id: item.id,
        jadwal_id: item.jadwal_id,
        user_id: {
          text: item.user_name,
          value: item.user_id,
        },
      }),
        // this.form = item;
        (this.activeAction = "ubah");
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          var payload = {
            id: data.id,
            fungsi: 1,
          };
          this.$store
            .dispatch("jadwaladmin/savemember", [payload])
            .then(() => {
              this.formMember = {
                id: null,
                jadwal_id: null,
                user_id: null,
              };
              this.getdataMember();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
              this.$swal({
                icon: "success",
                title: "Terhapus!",
                text: "Data berhasil dihapus.",
                // timer: 1000,
                allowOutsideClick: true,
                customClass: {
                  confirmButton: "btn btn-success",
                },
                buttonsStyling: false,
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    TambahMember() {
      // user_id: this.user.id,

      let payload = this.formMember;

      if (this.formMember.id) {
        payload.id = this.formMember.id;
      }

      this.$store
        .dispatch("jadwaladmin/savemember", payload)
        .then(() => {
          if (this.activeAction == "ubah") {
            this.formMember.id = null;
          }
          this.resetForm();
          this.Modal = false;
          // this.ModalEdit = false;
          this.getdataMember();
          this.displaySuccess({
            text: "Member berhasil disimpan",
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    async getDataoptMember() {
      this.loading = true;
      let params = {
        paket_id: this.jadwalujianDataId.paket_id,
        type_member: this.jadwalujianDataId?.type,
      };
      this.$store
        .dispatch("paketTryout/indexMemberPaket", params)
        .then((response) => {
          this.loading = false;
          let member = response.data.data;
          this.itemMember = member;

          member.map((item) => {
            item.value = item.user_id;
            item.text = item.name;
          });
          this.optMember = member;
          this.totalRows2 = member.length;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
    async getdataMember() {
      const params = {
        jadwal_id: this.$route.params.id,
      };
      this.$store
        .dispatch("jadwaladmin/indexmember", params)
        .then((response) => {
          let dataMember = response.data.data;
          this.dataMember = dataMember;
          this.totalRows = this.dataMember.length;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
  },
  created() {},
  async mounted() {
    this.getDataoptMember();

    this.getdataMember();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
